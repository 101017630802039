import { isOnboardingRequired } from 'components/helpers/User.helpers';
import { Redirect as RedirectDOM } from 'react-router-dom';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';

const Redirect = ({ to }) => {
  const { isSignedIn } = $global.value;
  const { currentOrganization, user: { organizations = [] } } = $user.value;

  const RedirectUrl = isSignedIn ? '/onboarding' : '/login';

  if (isSignedIn) {
    if (isOnboardingRequired.value) {
      return (<RedirectDOM to="/onboarding" />);
    }

    if (isForbiddenOrganizationUser()) {
      return (<RedirectDOM to="/profile" />);
    }

    if (currentOrganization?.id && organizations?.length > 0) {
      return (<RedirectDOM to="/customers" />);
    }
  }

  return (<RedirectDOM to={to || RedirectUrl} />);
};

export default Redirect;
