// https://www.npmjs.com/package/iso-3166-2
const ISO_3166_2_COUNTRY_USA_DATA = {
  name: 'United States',
  sub: {
    'US-NY': { type: 'State', name: 'New York' },
    'US-PA': { type: 'State', name: 'Pennsylvania' },
    'US-TN': { type: 'State', name: 'Tennessee' },
    'US-VI': { type: 'Outlying area', name: 'Virgin Islands, U.S.' },
    'US-NV': { type: 'State', name: 'Nevada' },
    'US-NJ': { type: 'State', name: 'New Jersey' },
    'US-NH': { type: 'State', name: 'New Hampshire' },
    'US-VA': { type: 'State', name: 'Virginia' },
    'US-HI': { type: 'State', name: 'Hawaii' },
    'US-PR': { type: 'Outlying area', name: 'Puerto Rico' },
    'US-NM': { type: 'State', name: 'New Mexico' },
    'US-NC': { type: 'State', name: 'North Carolina' },
    'US-ND': { type: 'State', name: 'North Dakota' },
    'US-NE': { type: 'State', name: 'Nebraska' },
    'US-LA': { type: 'State', name: 'Louisiana' },
    'US-UT': { type: 'State', name: 'Utah' },
    'US-SD': { type: 'State', name: 'South Dakota' },
    'US-DC': { type: 'District', name: 'District of Columbia' },
    'US-DE': { type: 'State', name: 'Delaware' },
    'US-FL': { type: 'State', name: 'Florida' },
    'US-WA': { type: 'State', name: 'Washington' },
    'US-UM': {
      type: 'Outlying area',
      name: 'United States Minor Outlying Islands',
    },
    'US-KS': { type: 'State', name: 'Kansas' },
    'US-WI': { type: 'State', name: 'Wisconsin' },
    'US-OR': { type: 'State', name: 'Oregon' },
    'US-KY': { type: 'State', name: 'Kentucky' },
    'US-ME': { type: 'State', name: 'Maine' },
    'US-OH': { type: 'State', name: 'Ohio' },
    'US-IA': { type: 'State', name: 'Iowa' },
    'US-MD': { type: 'State', name: 'Maryland' },
    'US-ID': { type: 'State', name: 'Idaho' },
    'US-WY': { type: 'State', name: 'Wyoming' },
    'US-VT': { type: 'State', name: 'Vermont' },
    'US-RI': { type: 'State', name: 'Rhode Island' },
    'US-IN': { type: 'State', name: 'Indiana' },
    'US-IL': { type: 'State', name: 'Illinois' },
    'US-AK': { type: 'State', name: 'Alaska' },
    'US-TX': { type: 'State', name: 'Texas' },
    'US-CO': { type: 'State', name: 'Colorado' },
    'US-AR': { type: 'State', name: 'Arkansas' },
    'US-GU': { type: 'Outlying area', name: 'Guam' },
    'US-MA': { type: 'State', name: 'Massachusetts' },
    'US-AL': { type: 'State', name: 'Alabama' },
    'US-MO': { type: 'State', name: 'Missouri' },
    'US-MN': { type: 'State', name: 'Minnesota' },
    'US-WV': { type: 'State', name: 'West Virginia' },
    'US-CA': { type: 'State', name: 'California' },
    'US-OK': { type: 'State', name: 'Oklahoma' },
    'US-MI': { type: 'State', name: 'Michigan' },
    'US-GA': { type: 'State', name: 'Georgia' },
    'US-AZ': { type: 'State', name: 'Arizona' },
    'US-MT': { type: 'State', name: 'Montana' },
    'US-MS': { type: 'State', name: 'Mississippi' },
    'US-SC': { type: 'State', name: 'South Carolina' },
    'US-MP': { type: 'Outlying area', name: 'Northern Mariana Islands' },
    'US-AS': { type: 'Outlying area', name: 'American Samoa' },
    'US-CT': { type: 'State', name: 'Connecticut' },
  },
  code: 'US',
};

const USA_COUNTRY_SUBDIVISIONS = Object.values(ISO_3166_2_COUNTRY_USA_DATA.sub).map(({ name }) => name);

export const getUSACountrySubdivisionOptions = () => {
  const res = USA_COUNTRY_SUBDIVISIONS.map((subdivision) => ({
    label: subdivision,
    value: subdivision,
  }));
  res.unshift({ label: 'Select...', value: null });
  return res;
};

export const validateState = (input) => {
  if (!USA_COUNTRY_SUBDIVISIONS.includes(input)) {
    throw new Error('Invalid state.');
  }
};

export const validateZip = (zipCode) => {
  if (!/^\d{5}$/.test(zipCode)) {
    throw new Error('Invalid zip code');
  }
};

export const validateTaxIdCode = (taxIdCode) => {
  if (!/^\d{9}$/.test(taxIdCode)) {
    throw new Error('Invalid Tax ID Number');
  }
};
