import { Form } from 'react-bootstrap';
import SelectInput from 'components/global/Inputs/SelectInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import { $settingsSubscriptionIsBillingAddressRequired, $settingsSubscriptionBillingAddressForm, $settingsSubscriptionPurchaseDetail } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import { getUSACountrySubdivisionOptions } from 'utils/validation';

const BusinessAddressForm = () => {
  const { currentTransaction } = $settingsSubscriptionPurchaseDetail.value;
  const {
    isOrgAddressSame,
  } = $settingsSubscriptionBillingAddressForm.value;

  if (currentTransaction) return <></>;

  return (
    <Form.Group>
      <h4 className="fw-700 mt-32">Billing Address</h4>
      {!$settingsSubscriptionIsBillingAddressRequired.value && (
        <>
          <Form.Label htmlFor="lastName"><strong>Use my Organization address</strong></Form.Label>
          <Form.Check
            className="form-check-radio-custom mb-16"
            type="switch"
            checked={!!isOrgAddressSame}
            onChange={(e) => $settingsSubscriptionBillingAddressForm.update({ isOrgAddressSame: e.target.checked })}
          />
        </>
      )}
      {(!isOrgAddressSame || !!$settingsSubscriptionIsBillingAddressRequired.value) && (
        <>
          <Form.Label htmlFor="firstName"><strong>First Name</strong></Form.Label>
          <UniversalInput
            placeholder="First Name"
            className="mb-16"
            name="firstName"
            signal={$settingsSubscriptionBillingAddressForm}
          />
          <Form.Label htmlFor="lastName"><strong>Last Name</strong></Form.Label>
          <UniversalInput
            placeholder="Last Name"
            className="mb-16"
            name="lastName"
            signal={$settingsSubscriptionBillingAddressForm}
          />
          <Form.Label htmlFor="address"><strong>Address</strong></Form.Label>
          <UniversalInput
            placeholder="Address"
            className="mb-16"
            name="address"
            signal={$settingsSubscriptionBillingAddressForm}
          />
          <Form.Label htmlFor="city"><strong>City</strong></Form.Label>
          <UniversalInput
            placeholder="City"
            className="mb-16"
            name="city"
            signal={$settingsSubscriptionBillingAddressForm}
          />
          <Form.Label htmlFor="state"><strong>State</strong></Form.Label>
          <SelectInput
            className="w-100 mb-16"
            name="state"
            signal={$settingsSubscriptionBillingAddressForm}
            options={getUSACountrySubdivisionOptions()}
          />
          <Form.Label htmlFor="zipCode"><strong>Zip Code</strong></Form.Label>
          <UniversalInput
            placeholder="Zip Code"
            className="mb-16"
            name="zipCode"
            signal={$settingsSubscriptionBillingAddressForm}
          />
        </>
      )}

    </Form.Group>
  );
};

export default BusinessAddressForm;
