import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { handleCancelPurchase, handlePaymentGatewayLoadingSuccess, handlePurchase } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import BusinessAddressForm from 'components/views/SettingsSubscription/_components/BusinessAddressForm';
import { handleNotification } from 'components/global/Alert/Alert';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

const NuveiCreditCardPayment = memo(({ safeCharge }) => {
  const [cardNumber, setCardNumber] = useState(null);
  const [cardExpiry, setCardExpiry] = useState(null);
  const [cardCvc, setCardCvc] = useState(null);
  const [cardHolderName, setCardHolderName] = useState('');

  useEffect(() => {
    if (safeCharge) {
      const safeChargeFields = safeCharge.fields({
        fonts: [{ cssUrl: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap' }],
        locale: 'en',
        fontSize: '16px',
      });

      const cardNumberField = safeChargeFields.create('ccNumber', {});
      cardNumberField.attach('#card-number');

      const cardExpiryField = safeChargeFields.create('ccExpiration', {});
      cardExpiryField.attach('#card-expiry');

      const cardCvcField = safeChargeFields.create('ccCvc', {});
      cardCvcField.attach('#card-cvc');

      cardNumberField.on('ready', () => handlePaymentGatewayLoadingSuccess());

      setCardNumber(cardNumberField);
      setCardExpiry(cardExpiryField);
      setCardCvc(cardCvcField);
    }
  }, [safeCharge]);

  const handlePurchasePaymentSubmit = useCallback(() => {
    if (cardNumber && cardExpiry && cardCvc) {
      try {
        if (!cardHolderName) throw new Error('Card holder name is required.');
        if (!cardNumber.parentElm.classList.contains(cardNumber.stateClass.complete)) throw new Error('Invalid card number.');
        if (!cardExpiry.parentElm.classList.contains(cardExpiry.stateClass.complete)) throw new Error('Invalid card expiry.');
        if (!cardCvc.parentElm.classList.contains(cardCvc.stateClass.complete)) throw new Error('Invalid card cvc.');
      } catch (error) {
        handleNotification(error);
        return;
      }

      const createPaymentCallback = ({ sessionToken }) => new Promise((resolve, reject) => {
        try {
          safeCharge.createPayment(
            {
              sessionToken,
              cardHolderName,
              paymentOption: cardNumber,
            },
            (response) => {
              if (response.result === 'APPROVED') {
                resolve(response);
              } else {
                const reason = response.errorDescription ? String(response.errorDescription).toLowerCase() : '';
                const status = response.transactionStatus ? String(response.transactionStatus).toLowerCase() : 'failed';
                reject(new Error(`Transaction ${status}.${!status.includes(reason) ? ` Reason: ${capitalizeFirstLetter(reason)}.` : ''}`));
              }
            },
          );
        } catch (error) {
          reject(error);
        }
      });

      handlePurchase({ createPaymentCallback });
    }
  }, [safeCharge, cardHolderName, cardNumber, cardExpiry, cardCvc]);

  return (
    <>
      <Form.Group>
        <Form.Label htmlFor="cardHolderName"><strong>Name On Card</strong></Form.Label>
        <Form.Control
          id="cardHolderName"
          className="mb-16"
          placeholder="Card holder Name"
          type="text"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
        />

        <Form.Label htmlFor="cardNumber"><strong>Credit Card Number</strong></Form.Label>
        <div id="card-number" className="form-control mb-16" />

        <Form.Label htmlFor="card-expiry"><strong>Expiration Date</strong></Form.Label>
        <div id="card-expiry" className="form-control mb-16" />

        <Form.Label htmlFor="card-cvc"><strong>Security Code</strong></Form.Label>
        <div id="card-cvc" className="form-control mb-16" />

      </Form.Group>

      <BusinessAddressForm />

      <Row className="mx-0">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            onClick={() => handleCancelPurchase()}
            className="w-100 btn-lg mb-16 mt-24"
          >
            Cancel
          </Button>
        </Col>
        <Col className="pe-0">
          <Button
            variant="primary"
            onClick={() => handlePurchasePaymentSubmit()}
            className="w-100 btn-lg mb-16 mt-24"
          >
            Subscribe
          </Button>
        </Col>
      </Row>
    </>
  );
});

export default NuveiCreditCardPayment;
