import { computed, effect } from '@preact/signals-react';
import { handleNotification } from 'components/global/Alert/Alert';
import firstAvailableOrganization from 'utils/firstAvailableOrganization';
import { ORGANIZATION_USER_ROLE } from 'accru-client';
import history from 'utils/history';
import $user from 'signals/User.signals';

export const isOnboardingUserRequired = computed(() => !!$user.value.user && (!$user.value.user.first_name));
export const isOnboardingOrganizationRequired = computed(() => !!$user.value.user && (!$user.value.user.organizations?.length || !$user.value.currentOrganization?.subscriptionLevel));
export const isOnboardingRequired = computed(() => isOnboardingUserRequired.value || isOnboardingOrganizationRequired.value);

export const isImpersonating = computed(() => !!$user.value.user?.is_admin && $user.value.organizationOverride && $user.value.selectedOrganizationId === $user.value.organizationOverride?.organization_id);

/**
 * Finds the user reference in the organization by the organization ID.
 *
 * @param {string} organizationId - The ID of the organization to find.
 * @returns {import('accru-client').OrganizationUser | undefined} - The organization object if found, or undefined if not found.
 */
export const findOrganizationUser = (organizationId) => $user.value.user?.organizations?.find((org) => org.organization_id === organizationId);

/**
 * Finds an organization by its ID.
 *
 * @param {string} organizationId - The ID of the organization to find.
 * @returns {import('accru-client').Organization | undefined} - The organization object if found, or undefined if not found.
 */
export const findOrganization = (organizationId) => findOrganizationUser(organizationId)?.organization;

/**
 * Selects an organization based on the provided organization ID.
 *
 * @param {string} organizationId - The ID of the organization to select.
 * @returns {import('accru-client').Organization | null} The selected organization object, or null if the organization is not found.
 */
export const selectOrganization = (organizationId) => {
  if (!$user.value?.user) return null;

  const organization = findOrganization(organizationId);

  if (!organization) {
    handleNotification('Organization not found.', { variant: 'danger' });
    return null;
  }

  localStorage.setItem('accru:organization_id', organizationId);

  $user.update({
    organizationOverride: null,
    selectedOrganizationId: organizationId,
  });

  return organization;
};

export const selectOrganizationAndRedirect = (organizationId) => {
  const organization = selectOrganization(organizationId);

  if (organization) {
    history.push('/');
  }
};

export const selectAdminOrganization = (organization) => {
  if (!$user.value?.user) return null;

  $user.update({
    selectedOrganizationId: organization.id,
    organizationOverride: {
      organization,
      organization_id: organization.id,
      role: ORGANIZATION_USER_ROLE.OWNER,
    },
  });
};

export const selectFirstAvailableOrganizationAndRedirect = () => {
  const selectedOrganization = firstAvailableOrganization();

  if (selectedOrganization) {
    selectOrganizationAndRedirect(selectedOrganization.organization_id);
    return selectedOrganization;
  }
};

effect(() => {
  if (JSON.stringify($user.value) !== JSON.stringify($user.previousValue)) {
    const selectedOrganizationUser =
      $user.value.organizationOverride ||
      $user.value.user?.organizations?.find((org) => org.organization_id === $user.value.selectedOrganizationId && org.is_current_organization_user_seat_available) ||
      firstAvailableOrganization();

    $user.update({
      currentOrganization: {
        name: selectedOrganizationUser?.organization?.name,
        email: selectedOrganizationUser?.organization?.email,
        id: selectedOrganizationUser?.organization_id,
        role: selectedOrganizationUser?.role,
        subscriptionLevel: selectedOrganizationUser?.organization?.subscription_data?.subscription_level,

        organizationUser: selectedOrganizationUser,
        data: selectedOrganizationUser?.organization,
      },
    });
  }
}, [$user]);

export default $user;
